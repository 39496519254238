<template>
	<div class="detailindex">
		<!-- <ul class="detailindex-a detailindex-b">
			<li :class="(item.current == true ? 'current ' : '') + 'detailindex-c  detailindex-b'" v-for="(item, index) in progress" :key="index">
				<span class="detailindex-ab"></span>
				<a class="detailindex-ac">{{ item.name }}</a>
			</li>
		</ul> -->
		<div class="aviation-ba">
			<div class="aviation-jz">
				<!-- 详情最新头部改动  头部步骤条 -->
				<div class="aviation-jza">
					<div class="iconfont icon-baoxian2 aviation-jzc"></div>
					<div class="aviation-jzd">选择保险 Select</div>
				</div>
				<div class="aviation-jzb"></div>
				<div class="aviation-jza">
					<div class="iconfont icon-bianji2 aviation-jze aviation-jzee"></div>
					<div class="aviation-jzf">填写资料 Fill in</div>
				</div>
				<!-- <div class="aviation-jzb"></div>
				<div class="aviation-jza">
					<div class="iconfont icon-duigou aviation-jze"></div>
					<div class="aviation-jzf">确认资料 Confirm</div>
				</div> -->
				<div class="aviation-jzb"></div>
				<div class="aviation-jza">
					<div class="iconfont icon-xiangshang11 aviation-jze aviation-jzec"></div>
					<div class="aviation-jzf">提交订单 Submit</div>
				</div>
			</div>
		</div>
		<div class="detailindeximg">
			<el-row type="flex" justify="start" v-if="InsuranceDetail != ''">
				<div class="detailindeximg-a"><img src="../../assets/baoxian10.png" class="detailindeximg-aa" />
				</div>
				<div class="detailindeximg-b">
					<p class="detailindeximg-bp">{{ InsuranceDetail.RateDetail.ProductName }}</p>
					<div class="detailindeximg-bb">
						<span class="detailindeximg-bc">
							已被购买
							<i>{{ InsuranceDetail.RateDetail.Sale }}</i>
							次
						</span>
					</div>
					<div class="detailindeximg-bd">
						<div>
							<el-row class="detailindeximg-li" type="flex">
								<i class="detailindeximg-bx">保险计划：</i>
								<div class="detailindex-xx detailindex-xxx">
									<el-radio-group v-model="PlanCode">
										<el-radio border v-for="(item, index) in InsuranceDetail.Plans" :key="index"
											:label="item.PlanCode" @change="bindPlanChange">
											{{ item.PlanName }}
											<!-- icon-dibugouxuan图片名称 -->
											<span class=" iconfont icon-dibugouxuan" :class="[alipaya==item.PlanCode?'detailindex-dibu':'detailindex-dibua']"></span>
										</el-radio>
										<!-- <el-radio :label="2" border>
											申根无忧全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="3" border>
											申根完美
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="4" border>
											申根完美全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="5" border>
											全球探索
											<span class="detailindex-dibu iconfont icon-dibugouxuan"></span>
										</el-radio>
										<el-radio :label="6" border>
											全球探索全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="7" border>
											全球无忧
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="8" border>
											全球无忧全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="9" border>
											全球完美
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="10" border>
											全球完美全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="11" border>
											申根无忧延误升级版全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="12" border>
											申根无忧延误升级版
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="13" border>
											申根完美延误升级版全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="14" border>
											申根完美延误升级版
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="15" border>
											全球探索延误升级版全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="16" border>
											全球探索延误升级版
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="17" border>
											全球无忧延误升级版全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="18" border>
											全球无忧延误升级版
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="19" border>
											全球完美延误升级版全年计划
											<span class="detailindex-dibu iconfont "></span>
										</el-radio>
										<el-radio :label="20" border>
											全球完美延误升级版
											<span class="detailindex-dibu iconfont "></span>
										</el-radio> -->
									</el-radio-group>
								</div>
							</el-row>
							<div class="detailindeximg-li">
								<el-row type="flex" justify="start" v-show="false">
									<i class="detailindeximg-bx">选择人数：</i>
									<i class="detailindeximg-bx">成人：</i>
									<el-select v-model="InsurSearchInfo.AdultQty" placeholder="请选择成人" size="mini">
										<el-option v-for="item in peopleOptions" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
									<!-- <span class="detailindex-icona iconfont icon-xiangxia2"></span> -->
									<i class="detailindeximg-bx">儿童：</i>
									<el-select v-model="InsurSearchInfo.ChildQty" placeholder="请选择儿童" size="mini">
										<el-option v-for="item in peopleOptions" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
									<!-- <span class="detailindex-iconb iconfont icon-xiangxia2"></span> -->
									<!-- <el-select v-model="value3" placeholder="请选择老人" size="mini" popper-class="detailindex-el">
										<el-option v-for="item in options3" :key="item.value3" :label="item.label3" :value="item.value3"></el-option>
									</el-select>
									<span class="detailindex-iconc iconfont icon-xiangxia2"></span> -->
								</el-row>
							</div>
							<div class="detailindeximg-li detailindeximg-lia">
								<!-- <p class="detailindeximg-bi"><i class="detailindeximg-bx">(本计划保障期限 天可以选)</i></p> -->
								<el-row type="flex" justify="start">
									<i class="detailindeximg-bx detailinde-sj-a">保障日期：</i>
									<div>
										<!-- <el-date-picker class="detailinde-sj" v-model="value4" :clearable="false" type="date" placeholder="YYYY-MM-DD"></el-date-picker> -->
										<el-date-picker value-format="yyyy-MM-dd" v-model="dates" size="medium" class=""
											style="width: 250px;" type="daterange" range-separator="-"
											start-placeholder="开始日期" end-placeholder="结束日期"
											:picker-options="pickerOptions0" @change="changedate"></el-date-picker>
									</div>
									<!-- <i class="detailindeximg-bx detailinde-sj-a detailindeximg-bi">结束时间：</i>
									<div><el-date-picker class="detailinde-sj" v-model="value5" :clearable="false" type="date" placeholder="YYYY-MM-DD"></el-date-picker></div> -->
								</el-row>
								<span class="detailinde-sj-a detailindeximg-bi">
									保障期限：
									<i class="detailinde-baoz">{{ InsurSearchInfo.Days }}</i>
									<i class="detailinde-baoz-a">天</i>
								</span>
							</div>
						</div>
					</div>
					<div class="detailindeximg-c">
						<p>
							总价：
							<span class="detailinde-bot-a">
								￥
								<i class="detailinde-bot-b">{{ InsuranceDetail.RateDetail.SalePrice }}</i>
							</span>
							/份
						</p>
						<a href="javascript:;" @click="bindSelect" v-if="InsuranceDetail.RateDetail.SalePrice > 0">
							<button class="detailinde-bot"
								:disabled="InsuranceDetail.RateDetail.SalePrice == 0">购买</button>
						</a>
					</div>
				</div>
				<!-- <div class="detailindeximg-c">
					<p>
						总价：
						<span class="detailinde-bot-a">
							￥
							<i class="detailinde-bot-b">{{ InsuranceDetail.RateDetail.SalePrice }}</i>
						</span>
						/份
					</p>
					<a href="javascript:;" @click="bindSelect" v-if="InsuranceDetail.RateDetail.SalePrice > 0">
						<button class="detailinde-bot" :disabled="InsuranceDetail.RateDetail.SalePrice == 0">确定选择</button>
					</a>
				</div> -->
			</el-row>
		</div>
		<div class="particulars" v-show="false">
			<div class="particulars-a">
				<a href="#A1" class="xiaoshou">产品介绍</a>
				<a href="#A2">理赔服务</a>
				<!-- <a href="#A3">常见问题</a> -->
			</div>
		</div>
		<div class="product">
			<a name="A1">
				<div class="particulars-b">
					<div class="particulars-ba particulars-bs particulars-bss">
						<span class="particulars-bd iconfont icon-chanpinjieshao"></span>
						产品介绍
					</div>
					<div class="particulars-bc" v-if="InsuranceDetail != ''">
						<div class="particulars-bcc">
							<div class="product-yaa" v-for="(item, index) in InsuranceDetail.Features" :key="index">
								{{ index + 1 }}、{{ item.EnsureDescribe }}</div>
							<!-- <div>2、最高90天的住院津贴补助，助您安心康复。</div>
							<div>3、医疗运送费用赔偿，没有上限限制，以实际费用为准。</div>
							<div>4、多样选择，满足您不同的旅行保障需求。</div>
							<div>5、符合申根要求，申根签证必备保单。</div>
							<div>6、涵盖全球热门国家，一张保单安心游世界。</div> -->
							<!-- <div class="product-yaa">
								<div class="">6、</div>
								<div class="product-ya">易安财产全国服务热线：</div>
								<div class="">40001212121、适用范围：国内、国际航班</div>
							</div> -->
						</div>
					</div>
				</div>
			</a>
			<a name="A2">
				<div class="particulars-c" v-if="InsuranceDetail != ''">
					<div class="particulars-bg particulars-bs">
						<span class="particulars-bd iconfont icon-lipeifuwu"></span>
						理赔服务
					</div>
					<div class="particulars-bp">
						<div class="particulars-tab particulars-bcc">
							<el-table :data="InsuranceDetail.Premiums" border style="width: 100%"
								cell-class-name="particulars-tabl">
								<el-table-column prop="ItemName" label="理赔项目" align="center"
									label-class-name="particulars-ta"></el-table-column>
								<el-table-column prop="Cost" label="理赔金额" width="250" align="center"
									label-class-name="particulars-ta"></el-table-column>
								<!-- <el-table-column prop="ItemName" label="理赔项目" align="center" label-class-name="particulars-ta"></el-table-column>
								<el-table-column prop="Cost" label="理赔金额" width="94" align="center" label-class-name="particulars-ta"></el-table-column> -->
							</el-table>
						</div>
					</div>
				</div>
			</a>
			<!-- <a name="A3">
				<div class="particulars-d">
					<div class="particulars-bj particulars-bs">
						<span class="particulars-bd iconfont icon-changjianwenti"></span>
						常见问题
					</div>
					<div class="particulars-dc">
						<div class="particulars-bccc">
							<p class="particulars-dc-d">【个人问题】</p>
							<div class="particulars-dc-dc">
								<p class="particulars-dc-dcp"><b>1、“驴友”应如何选购适合自己的保险？</b></p>
								<p>
									一是要根据需要选择险种。旅游意外伤害保险适合乘坐交通工具出行的游客购买；旅游人身意外伤害保险适合参加探险游和惊险游的游客购买；二是自驾出游投保要全面。自驾车旅游组合保险产品将意外伤害、财产费用损失及责任保险三类险种结合为一体，为被保人在旅行期间出现的意外伤害、意外急性病医疗、行李及财产损失等方面提供经济补偿。
								</p>
							</div>
							<p class="particulars-dc-d">【交通旅游意外险常见问题】</p>
							<div class="particulars-dc-dc">
								<p class="particulars-dc-dcp"><b>1、“驴友”应如何选购适合自己的保险？</b></p>
								<p>
									一是要根据需要选择险种。旅游意外伤害保险适合乘坐交通工具出行的游客购买；旅游人身意外伤害保险适合参加探险游和惊险游的游客购买；二是自驾出游投保要全面。自驾车旅游组合保险产品将意外伤害、财产费用损失及责任保险三类险种结合为一体，为被保人在旅行期间出现的意外伤害、意外急性病医疗、行李及财产损失等方面提供经济补偿。
								</p>
							</div>
						</div>
					</div>
				</div>
			</a> -->
		</div>
	</div>
</template>

<script>
	import {
		getInsurSearchInfo,
		setInsurSearchInfo
	} from '@/utils/localdatas';

	var util = require('@/utils/util.js');
	var datehelper = require('@/utils/datehelper.js');
	var insApi = require('@/api/insApi.js'); //保险接口
	var that;

	export default {
		data() {
			return {
				radio: 5,
				alipaya: '',
				tableData: [{
						claim: '旅行延误（每5小时延误赔偿额：人民币300元）',
						money: '600',
						claim1: '行李延误（每8小时延误赔偿额：人民币500元）',
						money1: '1000'
					},
					{
						claim: '旅行延误（每5小时延误赔偿额：人民币300元）',
						money: '600',
						claim1: '行李延误（每8小时延误赔偿额：人民币500元）',
						money1: '1000'
					}
				],
				// progress: [
				// 	{
				// 		name: '选择保险',
				// 		current: true
				// 	},
				// 	{
				// 		name: '填写资料'
				// 	},
				// 	{
				// 		name: '确定订单'
				// 	},
				// 	{
				// 		name: '预订成功'
				// 	}
				// ],

				peopleOptions: [{
						value: '1',
						label: '1'
					},
					{
						value: '2',
						label: '2'
					},
					{
						value: '3',
						label: '3'
					},
					{
						value: '4',
						label: '4'
					},
					{
						value: '5',
						label: '5'
					},
					{
						value: '6',
						label: '6'
					},
					{
						value: '7',
						label: '7'
					},
					{
						value: '8',
						label: '8'
					},
					{
						value: '9',
						label: '9'
					}
				],
				options2: [{
						value2: '选项1',
						label2: '3'
					},
					{
						value2: '选项1',
						label2: '4'
					}
				],
				options3: [{
						value3: '选项1',
						label3: '5'
					},
					{
						value3: '选项1',
						label3: '6'
					}
				],
				value1: '',
				value2: '',
				value3: '',
				value4: '',
				value5: '',
				SelectID: '',
				InsurSearchInfo: '',
				PlanCode: '',
				ProductCode: '',
				InsuranceDetail: '',
				dates: [],
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				}
			};
		},
		//创建
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			this.$router.push({
				path: '/'
			}); 
			that.PlanCode = query.PlanCode;
			that.alipaya=that.PlanCode;
			that.ProductCode = query.ProductCode;
			that.InsurSearchInfo = getInsurSearchInfo();
			that.adultCount = that.InsurSearchInfo.AdultQty;
			that.childrenCount = that.InsurSearchInfo.ChildQty;
			that.dates = [that.InsurSearchInfo.StartDate, that.InsurSearchInfo.EndDate];

			console.log(that.InsurSearchInfo);

			getInsuranceDetail();
		},
		//方法
		methods: {
			//改变日期
			changedate(e) {
				that.InsurSearchInfo.StartDate = that.dates[0];
				that.InsurSearchInfo.EndDate = that.dates[1];
				// that.InsurSearchInfo.StartDate=moment(e).format('YYYY-MM-DD');
				// console.log(that.InsurSearchInfo.StartDate); //打印出开始日期
				var day = datehelper.getDaysBetween(that.InsurSearchInfo.StartDate, that.InsurSearchInfo.EndDate);
				that.InsurSearchInfo.Days = day; //将计算出来的day天数赋值给后台接口days天数
				// var parm = {
				// 	InsurSearchInfoJSON: JSON.stringify(that.InsurSearchInfo)
				// };
				// getInsurSearchInfoJSON(parm);
				console.log(day);

				setInsurSearchInfo(that.InsurSearchInfo);
				getInsuranceDetail();
			},
			bindPlanChange(e) {
				console.log(that.PlanCode);
				that.alipaya=that.PlanCode
				console.log(that.PlanCode)
				getInsuranceDetail();
			},
			//预定
			bindSelect(e) {
				let InsuranceDetail = that.InsuranceDetail;
				if (InsuranceDetail.RateDetail.SalePrice == 0) {
					that.$message('该产品已经售罄，请选择其他产品');
					return;
				}
				var parm = {
					InsuranceRateDetailJSON: JSON.stringify(that.InsuranceDetail.RateDetail)
				};
				insApi.BookerInsurance(parm, function(result) {
					if (result.code == 0) {
						that.$router.push({
							path: '/Insurance/booking'
						});
					} else {
						that.$alert(result.msg);
					}
				});
			}
		}
	};
	//获取查询的信息
	function getInsurSearchInfoJSON(parm) {
		insApi.GetInsurSearch(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.InsurSearchInfo = result.data.InsurSearchInfo;
				setInsurSearchInfo(that.InsurSearchInfo);
				//获得查询信息之后将查询的信息传入保险列表的接口去查询列表信息
				getInsuranceDetail();
			}
		});
	}

	function getInsuranceDetail() {
		var parm = {
			PlanCode: that.PlanCode,
			ProductCode: that.ProductCode,
			InsurSearchInfoJSON: JSON.stringify(that.InsurSearchInfo)
		};
		insApi.GetInsuranceDetail(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.InsuranceDetail = result.data.InsuranceDetail;
			}
		});
	}
</script>

<style lang="scss">
	@import '../../style/Insurance/detail.scss';
</style>
